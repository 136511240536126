<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex>
        <!-- <v-card> -->
        <v-card-title class="elevation-0">
          <span class="itemHeading">Contest Applications</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-layout wrap justify-end>
          <!-- <v-flex lg3 pa-2 align-self-start>
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fromDate"
                  label="From Date"
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fromDate"
                no-title
                @input="menu11 = false"
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex lg3 pa-2 align-self-start>
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="toDate"
                  label="To Date"
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="toDate"
                no-title
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-flex> -->
        </v-layout>
        <v-layout wrap justify-start v-if="category.length > 0">
          <v-flex xs12>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr class="RB">
                    <th class="text-left">Date</th>
                    <th class="text-left">Name</th>
                    <th class="text-left">Theme</th>
                    <th class="text-left">Actions</th>
                  </tr>
                </thead>
                <tbody class="text-left RR">
                  <tr v-for="(item, i) in category" :key="i">
                    <td>
                      <span style="font-family: kumbhMedium"
                        ><b> {{ DateFormatted(item.create_date) }}</b></span
                      >
                    </td>
                    <td>
                      <span
                        v-if="item.firstName"
                        style="font-family: kumbhMedium"
                        >{{ item.firstName }}</span
                      >
                      <span
                        v-if="item.lastName"
                        style="font-family: kumbhMedium"
                      >
                        {{ item.lastName }}
                      </span>
                    </td>
                    <td>
                      <span style="font-family: kumbhMedium"
                        ><b> {{ item.selectTheme }}</b></span
                      >
                    </td>
                    <td>
                      <v-btn
                        color="success"
                        style="font-family: kumbhMedium"
                        small
                        block
                        outlined
                        :to="'/viewQuiz?id=' + item._id"
                        >View</v-btn
                      >
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-flex>
        </v-layout>

        <v-layout wrap justify-start v-else>
          <v-flex py-16 xs12>
            <span class="RR"> No Applications found! </span>
          </v-flex>
        </v-layout>

        <!-- </v-card> -->
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center v-if="pages > 1">
      <v-flex align-self-center>
        <div class="pt-2">
          <v-pagination
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="#005f32"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import ImageComp from  "@/components/Common/singleImage";
import axios from "axios";
import moment from "moment";
export default {
  data() {
    return {
      json_fields: {
        Date: "Edate",
        name: "name",
        JobCategory: "cat",
        JobTitle: "title",
      },

      //      json_fields: {
      //   Date: "this.category.createddate",
      //   name: "this.category.name",
      //   JobCategory: "cat",
      //   VolunteerTitle: "title",
      // },

      exel: {
        name: "",
        cat: "",
        Edate: "",
        title: "",
      },

      so: [],

      apSearch: null,
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      dialog: false,
      editdialog: false,
      editingitem: [],
      name: "",
      content: "",
      location: "",
      jobCategory: null,
      categoryList: [],
      titleList: [],
      pageCount: 0,
      appLoading: false,
      editedIndex: -1,
      currentPage: 1,
      g: false,
      perPage: 4,
      totalData: 0,
      totalRows: 0,
      dialogDelete: false,
      search: "",
      itemname: "",
      addcat: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
      category: [],
      data: [],
      coverImageFile: null,
      selectedFiles: [],
      image: null,
      coverImageArray: [],
      formDataCover: new FormData(),
      flagg: true,
      date: new Date(),
      fromDate: new Date(),
      toDate: new Date(),
      menu1: false,
      menu2: false,
    };
  },
  components: {
    // ImageComp,
  },
  mounted() {
    // if (localStorage.PRE == "/viewApplication") {
    //   this.fromDate = localStorage.fromDate;
    //   this.toDate = localStorage.toDate;
    //   this.jobCategory = localStorage.jCategory;
    //  if( localStorage.jSearch != 'null')
    //  {
    //   this.apSearch =localStorage.jSearch;
    //  }

    // }

    ///
    // if (this.fromDate) {
    //   // this.fromDate = null;
    //   this.fromDate.setDate(this.fromDate.getDate());
    //   this.fromDate = this.fromDate.toISOString().substr(0, 10);
    // }
    // if (this.toDate) {
    //   // this.toDate = null;
    //   this.toDate.setDate(this.toDate.getDate());
    //   this.toDate = this.toDate.toISOString().substr(0, 10);
    // }
    this.getData();
  },
  watch: {
    // jobCategory() {
    //   this.getData();
    // },
    // fromDate() {
    //   this.getData();
    // },
    // toDate() {
    //   this.getData();
    // },
    currentPage() {
      this.getData();
    },
  },

  methods: {
    DateFormatted(params) {
      // moment().format("dddd, MMMM Do YYYY, h:mm:ss a")
      // return params ? moment(params).format("DD-MMMM-Y <br> hh:mm A") : "";

      params = moment(params).subtract(5, "h").subtract(30, "m");
      return params ? moment(params).format("DD-MMM-Y  hh:mm A") : "";
    },
    getdData() {
      this.editdialog = false;
      this.getData();
    },

    getData() {
      // if (this.fromDate != null) {
      //   localStorage.setItem("fromDate", this.fromDate);
      // }
      // else
      // {
      //   localStorage.setItem("fromDate", this.fromDate);
      // }
      // if (this.toDate != null) {
      //   localStorage.setItem("toDate", this.toDate);
      // }

      this.appLoading = true;

      //
      //  if(this.fromDate==null)
      // this.fromDate=''

      axios({
        url: "/quiz/form/getlist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          page: this.currentPage,
          limit: 20,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.category = response.data.data;
          this.totalData = response.data.totalLength;
          if (this.totalData > 0) {
            this.pages = Math.ceil(this.totalData / response.data.limit);
          } else this.pages = 1;
          this.so = [];
          // if (response.data.data.length > 0) {
          //   for (let i = 0; i <= response.data.data.length; i++) {
          //     this.exel.name = response.data.data[i].name;
          //     this.exel.cat = response.data.data[i].jobCategory.name;
          //     this.exel.title = response.data.data[i].jobTitle.name;
          //     var c;
          //     c = response.data.data[i].createddate;
          //     this.exel.Edate = this.DateFormatted(c);
          //     // console.log("formate date", this.exel.Edate);
          //     // this.so[i] = this.exel;

          //     this.so.push(JSON.parse(JSON.stringify(this.exel)));

          //     this.excel = {};
          //     // console.log("test", this.exel);
          //   }
          //   // console.log("00000000", this.exel);
          // }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    // search

    //end
    dialogclose() {
      this.name = null;
      this.dialog = false;
    },
  },
};
</script>
<style scoped>
.cl >>> fieldset {
  border-color: rgba(250, 162, 0, 0.986);
  border-radius: 50px;
}
</style>
